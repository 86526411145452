// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UnderscoreFancyScss from "./UnderscoreFancy.scss";

var css = UnderscoreFancyScss;

function UnderscoreFancy(props) {
  var match = React.useState(function () {
        
      });
  var setWidth = match[1];
  var width = match[0];
  var setUnderscoreTriangleWrapperRef = function (node) {
    if (!(node == null)) {
      return setWidth(function (param) {
                  return node.getBoundingClientRect().width;
                });
    }
    
  };
  var tmp;
  switch (props.color) {
    case "Teal" :
        tmp = css.teal;
        break;
    case "Red" :
        tmp = css.red;
        break;
    case "Orange" :
        tmp = css.orange;
        break;
    
  }
  return JsxRuntime.jsxs("span", {
              children: [
                props.children,
                JsxRuntime.jsx("span", {
                      className: Cx.cx([
                            css.underscoreTriangle,
                            tmp
                          ]),
                      style: {
                        borderWidth: "3px " + (
                          width !== undefined ? String(width + 2.0) : String(0)
                        ) + "px 0 3px"
                      }
                    })
              ],
              ref: Caml_option.some(setUnderscoreTriangleWrapperRef),
              className: css.underscoreTriangleWrapper
            });
}

var make = UnderscoreFancy;

export {
  css ,
  make ,
}
/* css Not a pure module */
