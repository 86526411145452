// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import HeaderDotsSvg from "../assets/header-dots.svg";
import * as BannerRequestEstimateScss from "./BannerRequestEstimate.scss";

var css = BannerRequestEstimateScss;

var headerDotsSvg = HeaderDotsSvg;

function BannerRequestEstimate(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(H2.make, {
                                  className: css.title,
                                  children: props.title
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Button.make, {
                                          size: "MD",
                                          color: props.color,
                                          className: css.buttonRequestEstimate,
                                          onClick: props.onButtonClick,
                                          children: props.buttonTitle
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: props.buttonSubtitleComponent
                                        })
                                  ],
                                  className: css.buttonColumn
                                })
                          ],
                          className: css.bannerRow
                        }),
                    className: css.wrapperInner,
                    style: {
                      backgroundImage: "url(" + headerDotsSvg + ")",
                      backgroundSize: "cover"
                    }
                  }),
              className: css.wrapper
            });
}

var make = BannerRequestEstimate;

export {
  css ,
  headerDotsSvg ,
  make ,
}
/* css Not a pure module */
